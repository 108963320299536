/** @jsx jsx */
import { Styled, jsx, Flex, Box, Grid } from "theme-ui"
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Image from 'gatsby-image'
import Layout from "../components/layout"

import { Container } from '../components/item'
import SEO from "../components/seo"

import Button from "../components/button"

import BgImage from '../components/BgImage'

import BasicHero from '../components/BasicHero'

import Card from '../components/Card'

import ShapedCard from '../components/ShapedCard'

import RaptectLogo from '../images/icons/raptect-logo.svg'

import AdvancedSlider from '../components/AdvancedSlider'


export const query = graphql`
  query {
    hero: file(relativePath: { eq: "Raptect-homepage-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tint: file(relativePath: { eq: "tint-wrap-image.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carFilm: file(relativePath: { eq: "car-film.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    homeFilm: file(relativePath: { eq: "home-film.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    boatFilm: file(relativePath: { eq: "boat-film.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    auto: file(relativePath: { eq: "auto-use-image.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    marine: file(relativePath: { eq: "marine-resource.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    architecture: file(relativePath: { eq: "architecture-use-image.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    interior: file(relativePath: { eq: "interior-shot.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    purpleBackground: file(relativePath: { eq: "purple-bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whiteBlueBackground: file(relativePath: { eq: "white-blue-abstract-bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tintRoll: file(relativePath: { eq: "tint-roll.png"}) {
      childImageSharp {
        fluid(maxWidth: 410) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    midPageImageDesktop: file(relativePath: { eq: "car-house-boat.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    midPageImageMobile: file(relativePath: { eq: "3-picture-slant-mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    applications: allApplicationsJson {
      edges {
        node {
          title
          path
          excerpt
          excerpt_image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

const IndexPage = ({data}) => {

  const slantSources = [
    data.midPageImageMobile.childImageSharp.fluid,
    {
      ...data.midPageImageDesktop.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <Layout>
    <SEO 
        title="Window Tint for Automotive & Marine | Raptect Window Tint"
        description="Raptect offers premium nano ceramic window tint and window film. The core product is designed to work with marine, automative, and residential applications that require window tinting and films for UV protection and defense."
    />

    <BasicHero 
      fluid={data.hero.childImageSharp.fluid}  
      title={"Boat Picture"} 
      heroTitle={"Cool it. Protect it. Enjoy it."}
      heroText={"Sleek, high-tech window film solutions for vehicles, architecture, and marine craft."}
      shapeColor={'rgba(0,0,0,0.8)'}
    />

    <Box as="section" bg="white" my={13}>
      <ShapedCard
        sx={{color: 'white'}}
        fluidImg={data.tintRoll.childImageSharp.fluid}
        to={'/products'} 
        title='Nano Ceramic Window Tint'
        text="The highest-quality, longest-lasting window protection on the market. Raptect nano ceramic window tint filters 99% of UV and 95% of infrared rays. Long-lasting and cost-effective, it protects privacy, interiors, and the windows themselves while conserving energy and controlling temperatures."/>
    </Box>

    {/*
    <Box as="section" bg="white" color="black">
      <Container pb={13}>
        <Flex sx={{justifyContent: 'space-between', flexDirection: ['column', 'row'], alignItems: ['center', 'stretch']}}>
          <Card
            color='black'
            title="Auto Window Tint"
            titleColor='black'
            fluidImg={data.tintRoll.childImageSharp.fluid}
            roundEdges
            shadow
            text="Protection and privacy without glare, headlight distortion, or reduced visibility."
          >
          <Button text="Learn More" />
          </Card>
          <Card
            color='black'
            title="Marine Window Tint"
            titleColor='black'
            fluidImg={data.tintRoll.childImageSharp.fluid}
            roundEdges
            shadow
            text="Marine-grade window tints for reduced fuel consumption, glare, heat gain, and UV damage."
          >
          <Button text="Learn More" />
          </Card>
          <Card
            color='black'
            title="Architectural Window Tint"
            titleColor='black'
            fluidImg={data.tintRoll.childImageSharp.fluid}
            roundEdges
            shadow
            text="Glass protection and UV/infrared insulation for sun damage protection and temperature control."
          >
          <Button text="Learn More" />
          </Card>
        </Flex>
      </Container>
    </Box> 
    */}

    <AdvancedSlider data={data.applications.edges} />

    <BgImage
      fluid={data.purpleBackground.childImageSharp.fluid}
      title="abstract background"
    >
      <Container py={13} sx={{
        textAlign: 'center',
        color: 'white'
      }}>
        <Styled.h2 as="span" sx={{
          textAlign: 'center',
          fontWeight: 'bold'
        }}>Industries we serve</Styled.h2>
        <Flex width='100%' sx={{
          py: '40px',
          justifyContent: 'space-between',
          flexDirection: ['column', 'row'],
          alignItems: ['center', 'flex-start'],
        }}>
        <Card
            color='white'
            titleColor='white'
            fluidImg={data.auto.childImageSharp.fluid}
            title="auto" 
            text="Vehicle windows are damage-prone. Improve their safety, lifespan, and sun protection with professional tinting." 
          />
          <Card
            color='white'
            titleColor='white'
            roundImage
            fluidImg={data.marine.childImageSharp.fluid}
            title="marine" 
            text="Shipbuilding industries and boat repair businesses alike need quality tints to reduce UV damage, breakage risks, heat, and glare." 
          />
          <Card
            color='white'
            titleColor='white'
            roundImage
            fluidImg={data.architecture.childImageSharp.fluid}
            title="architecture" 
            text="Enhance window performance with Raptect heating, cooling, and UV solutions for homes and commercial buildings." 
          />
          </Flex>
          <Button variant={'whiteOutline'} to={'/industry-resources'} text="Learn More" sx={{minWidth: '250px'}}/>
      </Container>
    </BgImage>

    <BgImage
      title=""
      fluid={data.whiteBlueBackground.childImageSharp.fluid}
    >
      <Container py={'150px'} sx={{textAlign: 'center'}}>
        <Flex 
          width={'100%'}
          sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}
        >
          <Styled.h2 sx={{textTransform: 'uppercase'}}>Solar Defense Systems</Styled.h2>
          <Styled.p sx={{variant: 'text.body', maxWidth: '500px'}}>Let in the light while rejecting heat and harmful rays - for decades. Raptect’s multi-purpose technology is designed to go the distance.</Styled.p>
        </Flex>
      </Container>
    </BgImage>

    <Image fluid={slantSources} width="100%" />

    <Box as="section" bg='green'>
      <Container py={13} sx={{color: 'white', textAlign: 'center'}}>
        <Flex sx={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <Styled.h2 sx={{textTransform: 'uppercase'}}>Protect Your Investment</Styled.h2>
          <Styled.p sx={{variant: 'text.body', maxWidth: '830px'}}>Spend where it counts. With Raptect, windows perform better and last longer than they do with other treatments. Our tints strengthen windows and hold in shattered glass, extending your property's lifespan and even increasing its value.</Styled.p>
        </Flex>
      </Container>
    </Box>

    <Box as="section">
      <Container py={13}>
        <Flex sx={{justifyContent: 'space-between', flexDirection: ['column', 'row'], alignItems: 'center'}}>
          <div sx={{flex: '1 1 65%'}}>
            <Styled.h2 sx={{textTransform: 'uppercase'}}>Keep Your Cool</Styled.h2>
            <Styled.p sx={{variant: 'text.body', maxWidth: '550px'}}>Long-lasting cooling and comfort, sleek performance, and the highest standard in protection and value. Our guarantee.</Styled.p>
          </div>
          <div sx={{flex: '1 1 35%'}}>
            <RaptectLogo sx={{width: '375px', height: 'auto', marginTop: [8, 0]}}/>
          </div>
        </Flex>
      </Container>
    </Box>

    <Box as="section" bg="primary" color="white">
      <Container py={13} sx={{textAlign: 'center'}}>
        <Styled.h2 sx={{ textTransform: 'uppercase', color: 'white' }}>Industry Resources</Styled.h2>
        <Grid width={[300, 350]} gap={['16px', '30px', '50px']} my={11} sx={{ color: 'white' }}>
          <Box sx={{textAlign: 'center'}}>
            <Styled.h4>Automotive</Styled.h4>
            <Styled.p fontSize={0}>Window tint is an easy method to reduce heat absorption, lower damaging UV rays and heighten safety and security.</Styled.p>
          </Box>
          <Box sx={{textAlign: 'center'}}>
            <Styled.h4>Mass Transportation</Styled.h4>
            <Styled.p fontSize={0}>Bring temperatures down to heighten comfort and safety on buses, trains, and other transportation systems.</Styled.p>
          </Box>
          <Box sx={{textAlign: 'center'}}>
            <Styled.h4>Marine</Styled.h4>
            <Styled.p fontSize={0}>Boats and marine craft deal with constant sun and glare exposure. Window tints are the perfect solution for safety and comfort.</Styled.p>
          </Box>
          <Box sx={{textAlign: 'center'}}>
            <Styled.h4>Architecture</Styled.h4>
            <Styled.p fontSize={0}>One of the easiest, most effective solutions for design problems. Enhance style and sleekness while ensuring comfort in even the largest buildings.</Styled.p>
          </Box>
          <Box sx={{textAlign: 'center'}}>
            <Styled.h4>Commercial</Styled.h4>
            <Styled.p fontSize={0}>Protect people and furnishings from UV damage, improve your energy use, and provide an added layer of security.</Styled.p>
          </Box>
          <Box sx={{textAlign: 'center'}}>
            <Styled.h4>Residential</Styled.h4>
            <Styled.p fontSize={0}>Bring down energy costs, up your home’s privacy factor, and protect against harm from shattered windows or intruders.</Styled.p>
          </Box>
        </Grid>
        <Button
          variant='whiteOutline'
          to="/products" 
          text="See Products" 
          sx={{ width: '100%', maxWidth: '350px'}} />
      </Container>
    </Box> 
  
  </Layout>

  )
  
}

export default IndexPage
